.tile {
    width: 80%;
    max-width: 1000px;
    background-color: rgba(255, 255, 255);
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.065);
    margin: 0 auto;
    height: auto;
    border-radius: 20px;
    padding: 30px;
    /* margin-top: 100px; */
    transition: all .5s;
}

.tileTitle {
    font-size: 3em;
    font-weight: 500;

}

.itemTitle {
    font-size: 1.5em;
    font-weight: 500;
    margin-bottom: 5px;
}

.tileBody {
    font-size: 1.2em;
}

@media (max-width:1200px){
    .tile {
        max-width: 90%;
    }
}

.wrapper {
    /* width: 100%; */
    margin-bottom: 0px;
}

.bentoFrame {
    margin: auto;
    /* margin-top: 50px; */
    /* display: flex;
    justify-content: space-evenly; */
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: 1fr;
    /* grid-template-areas: 'top' 'left' '2' 'bottom'; */
    width: 100%;
    /* height: 50%; */
    justify-content: space-between;
}


.item {
    display: flex;
    flex-direction: column;
    /* background-color: color-mix(in srgb, var(--accent) 10%, transparent); */
    background-color: rgba(255, 255, 255, 0.104);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.045);
    padding: 20px;
    border-radius: 15px;
    margin: 10px;

    font-size: 1.2em;
    position: relative;
    transition: .5s all;
    
    &:hover {
        
        /* background-color: var(--accent);
        color: white; */
        scale: 1.1;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
        z-index: 999;
        transition: .5s all;
    }
}

.itemIcon {
    font-size: 3em;
    text-align: center;
    margin: 0 auto;
    position: absolute;
    bottom: 0;
    display: hidden;
}

@media (max-width: 1200px){
    .bentoFrame {
        grid-template-columns: 1fr;
        grid-template-rows: 33% 33% 33%;
    }

    .tileTitle {
        font-size: 2.5em;
    }

    .item {
        /* height: 200px; */
        padding-bottom: 40px;
    }
}

.dark .tile {
    background-color: rgba(5, 5, 5, 0.66);
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.265);
}
.dark .item {
    
    background-color: rgba(255, 255, 255, 0.05)
}
.contactLink{
    text-decoration: none;
    color: var(--accent)
}