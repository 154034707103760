.skillsList {
    /* display: flex;
    flex-direction: column;
    gap: 10%; */
    /* flex-wrap: wrap; */
    /* flex-basis: 20%; */
    /* justify-content: space-evenly; */
    /* text-align: center; */
    max-width: 800px;
    
    margin: 0 auto;
    margin-top: 30px;
}

.skillsItem {
    margin: 0 auto;
    display: inline-block;
    text-align: center;
    width: 100px;
    margin-top: 20px;
    margin-bottom: 20px;
    transition: scale .4s ease-out;
    &:hover {
        scale: 1.2;
        transition: scale .3s ease-out;
    }

}

.skillsIcon {
    height: 60px;
    margin-bottom: 10px;
    /* width: 80px; */
}

.dark .skillsIcon {
    filter: invert();
}

.skillsGroup {
    margin: 0 auto;
    text-align: center;
    /* background-color: red; */
    /* display: flex; */
    /* justify-content: space-around; */
    max-width: 800px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

    
}

.skillsHeader {
    font-size: 1.5em;
    font-weight: 500;
    margin-bottom: 5px;
    margin-top: 20px;



}

.skillsBreak {
    display: none;
}

@media (max-width:1200px){

    .skillsBreak {
        display: block;
    }

    .skillsGroup {
        grid-template-columns: 1fr 1fr 1fr;
        justify-content: center;
    }
    
}