@font-face {
  font-family: 'DM Sans';
  src: local('DM Sans'), url('./assets/font/DMSans-VariableFont_opsz\,wght.ttf') format('truetype');
}

body {
  
  margin: auto;
}

* {
  font-family: -apple-system, BlinkMacSystemFont, 'DM Sans', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --accent-highlight: rgb(180, 153, 251);
  --accent: rgb(146, 110, 243);
  --accent-dark: rgb(110, 72, 213);
  --bg-dark: rgb(15, 15, 15);
  --bg: rgb(250, 250, 250);
}

html {
  background-color: rgb(250, 250, 250);
  color: rgb(15, 15, 15);

  background: radial-gradient(circle at 50% 120%, 
  var(--accent) 10%, 
  var(--bg) 60%);
}

.dark {
  background-color: rgb(15, 15, 15);
  color: rgb(250, 250, 250);

  background: radial-gradient(circle at 50% 120%, 
  var(--accent) 10%, 
  var(--bg-dark) 60%);
}