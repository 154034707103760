.NavBar {
  margin: 0 auto;
  left: 0;
  right: 0;
  height: 80px;
  max-width: 100%;
  /* background-color: rgb(255, 247, 247); */
  /* background-color: rgb(168, 114, 114); */
  /* box-shadow: inset0px 0px 40px rgba(0, 0, 0, .1); */
  display: grid;
  grid-template-columns: 1fr 1px 1fr 1px 1fr;
  
  justify-content: space-between;
  align-items: center;
  /* position: fixed; */
  /* top: 0; */
  /* color: black; */
  padding: 0px 20px 0px 20px;
}

.title {
  font-size: 1.5em;
  letter-spacing: -.015em;
}

.links a{
    position: relative;
    top: 4px;
    text-decoration: none;
    color: var(--text);
    opacity: .7;

    transition: all .3s;
}

.links a:hover {
  opacity: 1;
  font-size: 1.1em;
  transition: all .2s;
}

.links {
  margin: 0 auto;
}

.rightNav {
  margin-right: 0;
  justify-self: end;
}

.webName {
  font-weight: 550;
  transition: all .3s;
}

.webName:hover {
  color: var(--accent);
  transition: all .1s;
}

.modeToggle {
  position: relative;
  top: -6px;
  padding-right: 20px;
  opacity: .3;
  transition: all .5s;
}

.modeToggle:hover {
  opacity: 1;
  transition: all .2s;
}

@media (max-width:800px){
    .links {
        display:none;
    }
    .title {
      display: none;
    }
    .NavBar {
      grid-template-columns: 1px 1px 1fr;
    }
}