.App {
  height: 100vh;
  width: 100vw;
  position: relative;
  user-select:none;

  background-color: rgb(243, 241, 110);
  background: radial-gradient(circle at 50% 120%, 
  var(--accent) 10%, 
  var(--background) 60%);
  /* background: linear-gradient(15deg, rgb(3, 2, 18) 0%, rgba(28,17,41,1) 60%, rgb(34, 20, 71) 80%, rgb(96, 70, 161) 100%);  */
  
  background-attachment: fixed;
  overflow-x: hidden;
  
  color:var(--text);


}

.center {
  text-align: center;
}

.content {
  /* position: relative; */
  /* height: auto; */
  /* min-height: 100%; */
  padding-bottom: 200px;
}

.customButton {
  outline: none;
  border: none;
  border-radius: 10px;
  padding: 10px;
  background-color: var(--accent);
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 500;
  transition: all .2s;
  color: rgb(250,250,250);
  margin-top: 20px;
  cursor: pointer;

}

.skeu {

  box-shadow: inset 0px 2px 5px rgb(255,255,255,.3), inset 0px -3px 8px var(--accent-dark), 0px 2px 30px var(--accent-highlight), inset 0px 0px 4px 4px rgb(255, 255, 255, .4);
  height: 50px;
  width: 200px;
  font-size: 1.15em;
  font-weight: 600;
  background: linear-gradient(var(--accent-highlight), var(--accent));
  border-radius: 18px;
  /* border: 2px solid var(--accent); */
}

.skeu:hover {
  /* scale: 1.1; */
  /* animation-name: bounce;
  animation-duration: .6s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out; */
  /* box-shadow: inset 0px -2px 0px rgba(255, 255, 255, 0.2); */
  background: linear-gradient(var(--accent), var(--accent-highlight));
  box-shadow:  inset 0px 2px 8px var(--accent-dark), inset 0px -5px 5px rgba(255, 255, 255, 0.3), 0px 0px 25px var(--accent-highlight), inset 0px 2px 6px 2px rgb(0, 0, 0, .1);
  /* border: 2px solid var(--accent); */
  /* font-size: 1.1em; */
  scale: .98;
}

.dark .skeu {
  box-shadow: inset 0px 2px 5px rgb(255,255,255,.2), inset 0px -3px 8px var(--accent-dark), 0px 2px 30px var(--accent-dark), inset 0px 0px 4px 4px rgb(255, 255, 255, .2);
  
  background: linear-gradient(var(--accent), var(--accent-dark));
}

.dark .skeu:hover {
  background: linear-gradient(var(--accent-dark), var(--accent));
  box-shadow:  inset 0px 2px 8px var(--accent-dark), inset 0px -5px 5px rgba(255, 255, 255, 0.3), 0px 0px 25px var(--accent-dark), inset 0px 2px 6px 2px rgb(0, 0, 0, .1);

}

@keyframes bounce {
  0% {
    scale: 1;
  }
  50% {
    scale: 1.05;
  }
  100% {
    scale: 1;
  }
}
.customButton:hover {
  /* background-color: var(--accent); */
  /* box-shadow: 5px 5px 20px rgba(0, 0, 0, 1); */
  /* box-shadow: inset 0px 0px 0px 1px rgba(255, 255, 255, 0.7); */
  /* scale: 1.1; */
  transition: all .2s;
}