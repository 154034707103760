.carouselPhoto {
    height: 500px;
    margin: 0 auto;
    border-radius: 10px;
    object-fit: cover;
    width: 1000px;
    object-position: top;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
    max-width: 95%;
}

.carousel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    justify-content: center;
}


.carouselDescription {
    /* font-style: italic; */
    /* opacity: .7; */
    color: white;
    padding-top: 50px;
    padding-bottom: 20px;
    position: absolute;
    /* top: 0; */
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 95%;
    height: 430px;
    border-radius: 9px;
    text-shadow: 0px 2px 5px black;
    bottom: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.608), rgba(0, 0, 0, 0.1) 30%, rgba(255, 255, 255, 0) 70%);
    background-color: rgba(0, 0, 0, 0.285);
    opacity: 0;
    transition: all .5s;
    &:hover{
        opacity: 1;
        transition: all .5s;
    }
}

.carouselTag {
    width: 100%;
    height: 53%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.carousel a {
    text-decoration: none;
    color: inherit;
}

.carouselButton {
    font-size: 2em;
    display: inline-block;
    margin-top: -.39em;
}


.carothing {
    width: 95%;
    height: 100%;
    display: grid;
    grid-template-columns: 40% 58% 2%;
    /* background-color: green; */
    border-radius: 20px;

    text-align: left;
    overflow: hidden;

    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.065);
    
}

.dark .carothing{
    background-color: rgba(5, 5, 5, 0.66);
}

.carouselThumbnail {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: top;
}

.carouselTitle {
    font-size: 2em;
    font-weight: 500;
}


.carouselText {
    padding: 20px;
}

.carouselPreview {
    border-radius: 20px;
    overflow: hidden;
}

.carouselTile {
    margin-bottom: 20px;
    margin-top: 40px;
    height: 300px;
}


@media (max-width:1200px){

    img {
        max-height: 400px;
    }

    .carouselDescription {
        max-height: 330px;
        top: 0px;
        display: none;
        opacity: 0;
    }

    .carouselTile {
        height: auto;
    }

    .carothing {
        grid-template-columns: 1fr;
    }

    .carouselPreview {
        height: 200px;
    }
}

.Projects {
    margin-top: 50px;
}
