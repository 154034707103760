.Footer {
    height: 150px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;

    position:relative;
    margin-top: -150px;
    clear: both;
    left:0;
    bottom:0;
    right:0;
    /* color: black; */
}

.footerContent {
  margin: 0 auto;
}

.footerTitle {
  opacity: 1;
  margin-bottom: 2px;
}

.noDecoration {
  text-decoration: none;
  color: inherit;
}

.Footer a {
  opacity: 1;
  transition: all .5s;
}

.Footer a:hover {
  /* text-shadow: 0px 0px 25px rgba(140, 120, 253, 1), 0px 0px 10px rgba(140, 120, 253, 1); */
  transition: all .5s;
  /* color: white; */
  opacity: 1;
}

.socialIcon {
  width: 25px;
  padding-top: 8px;
}

.dark .socialIcon {
  filter: invert();
}

.invert {
  filter: invert();
}